import React, { FC, useCallback } from 'react'

import css from 'styled-jsx/css'
import { useSetting, useSettingBvf } from '@ui/hooks'
import SfLink from '../next/SfLink'
import { iconPathBvf } from '@ui/helpers'
interface LogoProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  titleOnly?: boolean
  redirectable?: boolean
  logoSrc?: string
  height?: number
}

const Logo: FC<LogoProps> = ({
  titleOnly = false,
  className,
  redirectable = true,
  logoSrc = '',
  height = 0
}) => {
  const [storeSetting] = useSetting('store')
  const settingBvf = useSettingBvf()
  // const logo =  storeSetting?.general?.logo?.image

  // update logo support svg because cms not suppoted
  const logo = settingBvf?.logo ? settingBvf?.logo : 'wing-bra-logo.svg'
  const title = storeSetting?.general.name ?? ''
  const logoClass = ['logo', ...[className ? [className] : []]].join(' ')
  const Wrapper = useCallback(
    (prop: { children: JSX.Element }) => {
      return redirectable ? (
        <SfLink pathName="/" className={logoClass}>
          {prop.children}
        </SfLink>
      ) : (
        <div className={logoClass}>{prop.children}</div>
      )
    },
    [redirectable, logoClass]
  )

  return (
    <Wrapper>
      <>
        <style jsx global>
          {globalStyle}
        </style>
        <figure className="image">
          {!titleOnly && logo && (
            <img
              style={{ ...(height ? { height } : {}) }}
              src={iconPathBvf(logoSrc ? logoSrc : logo)}
              alt={title}
            />
          )}
        </figure>
        {(titleOnly || !logo) && <div className="text">{title}</div>}
      </>
    </Wrapper>
  )
}

const globalStyle = css.global`
  .logo {
    color: #303030;
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    font-weight: 900;
    line-height: 37px;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }

    img {
      max-height: 100px;
      max-width: 300px;
      object-fit: contain;
      object-position: left;
      height: 50px;
      width: auto;
      @media screen and (max-width: 768px) {
        height: var(--logo-height);
      }
    }
  }
`

export default Logo
